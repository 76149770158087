export const createMessage = (values) => {
  let value = `<div><strong>Miejscowość</strong></div>
    <div>${values.city}</div>`;
  values.city
    ? value
    : (value = `<div><strong>Wiadomość</strong></div>
    <div>${values.message}</div>`);
  return `
<div>
    <div><strong>Imię i nazwisko</strong></div>
    <div>${values.name}</div>
</div>
<div>
    <div><strong>Numer telefonu</strong></div>
    <div>${values.phone}</div>
</div> 
<div>
    <div><strong>Adres email</strong></div>
    <div>${values.email}</div>
</div>
<div>
    <div><strong>Kod pocztowy</strong></div>
    <div>${values.zipCode}</div>
</div>
<div>
    ${value}
</div>
`;
};
