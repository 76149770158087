import styled from "styled-components";

export const StyledRegularTitle = styled.h2`
  color: ${({ white, theme }) =>
    white ? theme.colors.white : theme.colors.lighterBlack};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: ${({ theme }) => theme.fontSizes.xxlarge};
  margin: 0;

  @media only screen and (max-width: 680px) {
    font-size: ${({ theme }) => theme.fontSizes.xlarge};
  }

  @media only screen and (max-width: 500px) {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;
