import styled from 'styled-components';

import BottomBG from '../../images/footerBottomImg.svg';
import Background from '../../images/footerBackground.png';

export const StyledFooterWrapper = styled.footer`
  height: 1096px;
  width: 100vw;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    text-align: center;
    height: 1300px;
  }
  @media only screen and (max-width: 425px) {
    height: 970px;
  }
`;

export const StyledFooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StyledFooterTextDivider = styled.p`
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  margin: 0;
  padding-bottom: 100px;
  @media only screen and (max-width: 680px) {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }

  @media only screen and (max-width: 500px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;

export const StyledFooterAdressDataBox = styled.div`
  margin-top: 100px;
  z-index: 30;
  width: 812px;
  height: 407px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 40px 50px 40px 50px;
  display: grid;
  grid-template-columns: 50% 50%;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.02);
  }
  @media only screen and (max-width: 1600px) {
    width: 60%;
  }
  @media only screen and (max-width: 1090px) {
    width: 70%;
    margin-top: 15%;
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 48%;
    padding: 8% 2.6% 8% 2.1%;
  }
  @media only screen and (max-width: 425px) {
    width: 85%;
  }
`;

export const StyledFooterDetails_1 = styled.div`
  display: flex;
  align-items: center;
  padding-top: 18%;
  justify-content: flex-start;
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const StyledFooterDetails_2 = styled.div`
  display: flex;
  align-items: center;
  padding-top: 18%;
  justify-content: flex-end;
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const StyledFooterDetailsWide = styled.div`
  display: flex;
  align-items: center;
  grid-column: 1 / span 2;
  width: 100%;
  padding-top: 30px;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const StyledFooterLink = styled.a``;
export const StyledFooterLinkImage = styled.img`
  border: none;
`;
export const StyledFooterVoltspotIcon = styled.img`
  @media only screen and (max-width: 768px) {
    display: flex;
    height: auto;
    width: 70%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
`;
export const StyledFooterDesc = styled.p`
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  display: flex;
  margin: 0;
  height: 72px;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: 1285px) {
    text-align: center;
    width: 200px;
    margin-left: auto;
  }
  @media only screen and (max-width: 768px) {
    justify-content: center;
    margin: 0 auto;
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const StyledFooterBoldDesc = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0;
  padding-left: 5%;
  @media only screen and (max-width: 1090px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;

export const StyledFooterBackground = styled.div`
  background: url(${Background}) no-repeat;
  background-size: cover;
  width: 100vw;
  height: 920px;
  position: absolute;
  bottom: -100px;
  left: -300px;

  z-index: 5;
  @media only screen and (max-width: 1330px) {
    left: 0;
  }
`;

export const StyledFooterBottom = styled.div`
  height: 19.2%;
  width: 100vw;
  background: url(${BottomBG}) center top;
  background-size: cover;
  position: absolute;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledFooterCopyright = styled.p`
  position: absolute;
  top: 53.5%;
  padding-left: 7.2%;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  @media only screen and (max-width: 1090px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
    padding-right: 25%;
  }
  @media only screen and (max-width: 425px) {
    top: 43.5%;
  }
`;

export const StyledFooterBtn = styled.a`
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 53.5%;
  right: 7.2%;
`;
export const StyledFooterIcon = styled.img``;

export const StyledFooterIconFb = styled.div`
  svg path {
    transition: fill 0.3s;
  }
  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colors.yellow};
    }
  }
`;
