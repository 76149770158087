import { useEffect, useState } from "react";
import { createMessage } from "./createMessage";
import axios from "axios";

type Value = {
  city: string;
  email: string;
  name: string;
  phone: string;
  zipCode: string;
  policy: boolean;
};

type UseFormDataPropsType = {
  value?: Value;
};

export const useFormData = (value: UseFormDataPropsType) => {
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (value) {
      setLoading(true);
      setError(false);

      axios
        .post("/api.php", {
          headers: { "content-type": "application/json" },
          message: createMessage(value),
        })
        .then(() => {
          setLoading(false);
          setSent(true);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    }
  }, [value]);

  return [sent, loading, error];
};
