import styled, { css } from "styled-components";

const StyledYellowBtn = css`
  background-color: ${({ theme }) => theme.colors.yellow};
  color: ${({ theme }) => theme.colors.black};
  svg path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

const StyledHoverYellowBtn = css`
  color: ${({ theme }) => theme.colors.white};
  svg path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledButton = styled.button`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  background-color: ${({ theme }) => theme.colors.lighterBlack};
  ${({ yellow }) => (yellow ? StyledYellowBtn : "")};
  font-family: Poppins, sans-serif;
  text-decoration: none;
  padding: 14px 22px;
  border-radius: 8px;
  outline: none;
  height: 37.6%;
  width: 85%;
  transition: all 0.3s;
  position: relative;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin-left: auto;
  }

  svg path {
    transition: fill 0.3s;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.yellow};
    svg path {
      fill: ${({ theme }) => theme.colors.yellow};
    }
    ${({ yellow }) => (yellow ? StyledHoverYellowBtn : "")};
  }
  @media only screen and (max-width: 768px) {
    width: 85%;
    height: 48%;
  }
  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  }
`;
