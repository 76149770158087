import React from 'react';

import {
  StyledFooterAdressDataBox,
  StyledFooterBackground,
  StyledFooterBoldDesc,
  StyledFooterBottom,
  StyledFooterBtn,
  StyledFooterContent,
  StyledFooterCopyright,
  StyledFooterDesc,
  StyledFooterDetails_1,
  StyledFooterDetails_2,
  StyledFooterDetailsWide,
  StyledFooterIcon,
  StyledFooterIconFb,
  StyledFooterTextDivider,
  StyledFooterVoltspotIcon,
  StyledFooterWrapper,
  StyledFooterLink,
  StyledFooterLinkImage,
} from './footer.style';
import { StyledBoldTitle } from '../../utils/styledComponents/boldTitle.style';
import { StyledCenter } from '../../utils/styledComponents/center.style';
import { LANG } from '../../lang/lang';

import VoltspotIcon from '../../images/logo.png';
import PhoneIcon from '../../images/phoneIcon.png';
import EmailIcon from '../../images/emailIcon.png';

const Footer = (): JSX.Element => {
  return (
    <StyledFooterWrapper>
      <StyledFooterBackground />
      <StyledCenter>
        <StyledFooterContent>
          <StyledFooterTextDivider>{LANG.PL.footerSection.divider}</StyledFooterTextDivider>
          <StyledBoldTitle>{LANG.PL.footerSection.title}</StyledBoldTitle>
          <StyledFooterAdressDataBox>
            <StyledFooterVoltspotIcon src={VoltspotIcon} />
            <StyledFooterDesc>{LANG.PL.footerSection.adressData.desc}</StyledFooterDesc>
            <StyledFooterDetails_1>
              <StyledFooterIcon src={PhoneIcon} />
              <StyledFooterBoldDesc>{LANG.PL.footerSection.adressData.phone}</StyledFooterBoldDesc>
            </StyledFooterDetails_1>
            <StyledFooterDetails_2>
              <StyledFooterIcon src={EmailIcon} />
              <StyledFooterBoldDesc href="mailto:biuro@voltpot.pl">
                {LANG.PL.footerSection.adressData.mail}
              </StyledFooterBoldDesc>
            </StyledFooterDetails_2>
            <StyledFooterDetailsWide>
              <StyledFooterLink target="_blank" href="https://wizytowka.rzetelnafirma.pl/VYI08NHO" rel="nofollow">
                <StyledFooterLinkImage
                  title="Kliknij i sprawdź status certyfikatu"
                  alt=""
                  src="https://aktywnybaner.rzetelnafirma.pl/ActiveBanner/GetActiveBannerImage/13/VYI08NHO"
                />
              </StyledFooterLink>
            </StyledFooterDetailsWide>
          </StyledFooterAdressDataBox>
        </StyledFooterContent>
      </StyledCenter>
      <StyledFooterBottom>
        <StyledFooterCopyright>{LANG.PL.footerSection.copyrights}</StyledFooterCopyright>
        <StyledFooterBtn href="https://www.facebook.com/voltspotspzoo">
          <StyledFooterIconFb>
            <svg
              id="Icons_-_Filled_-_Popular_Logos"
              data-name="Icons - Filled - Popular Logos"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
            >
              <rect id="Rectangle_4" data-name="Rectangle 4" width="39.815" height="39.815" fill="#fff" opacity="0" />
              <path
                id="Facebook"
                d="M40,20.122A20,20,0,1,0,16.875,40V25.939H11.8V20.122h5.078V15.689c0-5.043,2.986-7.829,7.554-7.829a30.589,30.589,0,0,1,4.477.393v4.952H26.384c-2.484,0-3.259,1.551-3.259,3.144v3.773h5.547l-.887,5.817h-4.66V40A20.09,20.09,0,0,0,40,20.122Z"
                fill="#fff"
              />
            </svg>
          </StyledFooterIconFb>
        </StyledFooterBtn>
      </StyledFooterBottom>
    </StyledFooterWrapper>
  );
};

export default Footer;
