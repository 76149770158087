import React from "react";
import { Link } from "gatsby";
import { StyledButton } from "./button.style";

const svgIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.882"
    height="13.691"
    viewBox="0 0 8.882 13.691"
  >
    <path
      id="Arrow_Caret_Back"
      data-name="Arrow Caret Back"
      d="M.517,8.026a1.607,1.607,0,0,1,0-2.361L6.184.43a1.607,1.607,0,0,1,2.7,1.181V12.081a1.607,1.607,0,0,1-2.7,1.181Z"
      transform="translate(8.882 13.691) rotate(180)"
      fill="#fff"
    />
  </svg>
);

type LinkButtonPropsType = {
  children: React.ReactNode;
  to: string;
  yellow: boolean;
};

type ButtonPropsType = {
  children: React.ReactNode;
  type: string;
  to: string;
  yellow: boolean;
};

const BasicButton = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <StyledButton>
      {children} {svgIcon}
    </StyledButton>
  );
};

const LinkButton = ({
  children,
  to,
  yellow,
}: LinkButtonPropsType): JSX.Element => {
  return (
    <StyledButton yellow={yellow ? 1 : 0} as={Link} to={to}>
      {children} {svgIcon}
    </StyledButton>
  );
};

const Button = ({
  children,
  type,
  to,
  yellow,
}: ButtonPropsType): JSX.Element => {
  if (type === "link") {
    return (
      <LinkButton yellow={yellow} to={to}>
        {children}
      </LinkButton>
    );
  }
  return <BasicButton>{children}</BasicButton>;
};

export default Button;
